@import "./variables";

.modal {
  &-footer {
    &__button {
      &--close {
        background-color: $grey-main;
        color: $white-main;
        padding: 5px 7px;
        border-radius: 4px;
        font-size: 18px;
        transition: 0.3s ease-in-out;

        &:hover {
          background-color: lighten($grey-main, 10%);
        }
      }
      &--save {
        @include button-primary;
        padding: 5px 15px;
        border-radius: 4px;
        font-size: 18px;
      }
    }
  }
}
