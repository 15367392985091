@import "./fonts";

$font-family-primary: "ArialRoundedMTBold";
$orange-main: #fb8406;
$orange-main-hover: #f39022;
$white-main: #ffffff;
$black-main: #000000;
$purple-main: rgba(55, 18, 60, 1);
$grey-main: rgb(128,128,128);

$border-grey: #dee2e6;

$font-grey: #7d7e80;

@mixin button-primary {
  background-color: $orange-main;
  border: none;
  color: $white-main;

  &:hover {
    background-color: $orange-main-hover;
    color: $white-main;
  }
}

@mixin button-secondary {
  padding: 3px 6px;
  border: 1px solid $orange-main;
  background-color: $white-main;
  color: $orange-main;
  transition: 0.2 ease-in-out;

  &:hover {
    background-color: $orange-main;
    color: $white-main;
  }
}
